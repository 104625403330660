import { useEffect, useState } from 'react'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../../config/firebase'
import { Slide } from 'react-slideshow-image'
import NewsCard from '../../NewsCard/NewsCard'
import { BarLoader } from 'react-spinners'
import './LastNewsSlider.scss'

export const LastNewsSlider = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [news, setNews] = useState([])
  const newsCollectionRef = collection(db, 'news')
  let [loading, setLoading] = useState(true)

  const indicators = (index) => <div className="indicator">{index + 1}</div>

  const cardWidth = 400

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const data = await getDocs(newsCollectionRef)
        const filteredDocs = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setNews(filteredDocs)
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
    fetchNews()
  }, [])

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  return (
    <div className="last-news-slider">
      {loading ? (
        <BarLoader
          loading={loading}
          color={'#F29E06'}
          height={10}
          width={'100%'}
        />
      ) : (
        <Slide
          slidesToScroll={1}
          slidesToShow={Math.floor((windowWidth * 0.8 - 2) / cardWidth)}
          arrows={false}
          autoplay={false}
          indicators={indicators}
          transitionDuration={300}
        >
          {news.map((newsItem) => (
            <NewsCard key={newsItem.id} newsItem={newsItem} />
          ))}
        </Slide>
      )}
    </div>
  )
}
