import './ProductItemPage.scss'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../config/firebase'
import { ProductItemSlider } from '../../components/Sliders/ProductItemSlider/ProductItemSlider'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader/Loader'

export default function ProductItemPage() {
  const { t, i18n } = useTranslation('global')
  const { productId } = useParams()
  const [productItem, setProductItem] = useState(null)
  const productCollectionItemRef = doc(db, 'products', productId)

  useEffect(() => {
    const fetchProductItem = async () => {
      try {
        const doc = await getDoc(productCollectionItemRef)
        setProductItem(doc.data())
      } catch (err) {
        console.log(err)
      }
    }

    fetchProductItem()
  }, [productId])
  if (productItem) {
    return (
      <div className="product-item-wrapper">
        <div className="product-item--top">
          <ProductItemSlider images={productItem?.images} />
          <div className="product-item--top--desc">
            <h1 className="product-item--top--desc-title">
              {productItem?.productName[i18n.resolvedLanguage]}
            </h1>
            <span className="product-item--top--desc-desc">
              {productItem?.productShortDescription[i18n.resolvedLanguage]}
            </span>
            <div className="product-item--top--desc-categ">
              <span className="product-item--top--desc-categ--categories">
                {t('productItem.categories')}
              </span>
              {productItem?.categories.map((categ, index) => (
                <span
                  className="product-item--top--desc-categ--categ"
                  key={index}
                >
                  {categ},
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="product-item--bottom">
          <h2 className="product-item--bottom-title">
            {t('productItem.description')}
          </h2>
          <span className="product-item--bottom-desc">
            {productItem?.productDescription[i18n.resolvedLanguage]}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="news-page--loader">
      <Loader loading={true} />
    </div>
  )
}
