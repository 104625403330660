import './ProductCard.scss'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function ProductCard({ product }) {
  const { t, i18n } = useTranslation('global')
  let navigate = useNavigate()
  return (
    <div className="product-card-wrapper">
      <div className="product-card--img">
        <img src={product?.images[0]} alt="product-img" />
        <button
          className="product-card--btn"
          onClick={() => navigate(`/catalog/${product.id}`)}
        >
          {t('catalogPage.readMoreButton')}
        </button>
      </div>
      <div className="product-card--desc">
        <h4 className="product-card--desc--name">
          {product?.productName[i18n.resolvedLanguage]}
        </h4>
        <div className="product-card--desc--categs">
          {product?.categories.map((categ, index) => (
            <span className="product-card--desc--categ" key={index}>
              {categ},
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}
