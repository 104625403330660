import { Route, Routes } from 'react-router-dom'
import MainPage from '../MainPage/MainPage'
import Header from '../../components/Header/Header'
import CatalogPage from '../CatalogPage/CatalogPage'
import NewsPage from '../NewsPage/NewsPage'
import Footer from '../../components/Footer/Footer'
import NewsItemPage from '../NewsItemPage/NewsItemPage'
import ProductItemPage from '../ProductItemPage/ProductItemPage'
import './Router.scss'
import { FirebaseAuthProvider } from '../../context/auth'
import ForumPage from '../ForumPage/ForumPage'
import SignInPage from '../SignInPage/SignInPage'
import SignUpPage from '../SignUpPage/SignUpPage'
import CreatePostPage from '../CreatePostPage/CreatePostPage'
import PostPage from '../PostPage/PostPage'
import ForgotPasswordPage from '../ForgotPasswordPage/ForgotPasswordPage'
import PrivacyPolicyPage from '../PrivacyPolicyPage/PrivacyPolicyPage'

export default function Router() {
  return (
    <FirebaseAuthProvider>
      <div className="all-content">
        <Header />
        <div className="content-place">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/sign-in" element={<SignInPage />} />
            <Route path="/not-sign-up" element={<SignUpPage />} />
            <Route path="/reset-password" element={<ForgotPasswordPage />} />
            <Route exact path="/news/:newsId" element={<NewsItemPage />} />
            <Route path="/catalog" element={<CatalogPage />} />
            <Route path="/catalog/:productId" element={<ProductItemPage />} />
            <Route path="/forum" element={<ForumPage />} />
            <Route path="/forum/posts/create" element={<CreatePostPage />} />
            <Route path="/forum/posts/:postId" element={<PostPage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </FirebaseAuthProvider>
  )
}
