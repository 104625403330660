import { db } from '../../config/firebase'
import { getDocs, collection } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import ProductCard from '../../components/ProductCard/ProductCard'
// import SearchIcon from '../../assets/searhIcon.svg'
import NotFoundIcon from '../../assets/notFoundIcon.svg'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader/Loader'
import './CatalogPage.scss'

export default function CatalogPage() {
  const { t } = useTranslation('global')
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [filteredProducts, setFilteredProducts] = useState([])
  // const [searchValue, setSearchValue] = useState('')

  const [productLoading, setProductLoading] = useState(true)
  const [categLoading, setCategLoading] = useState(true)

  const productsCollectionRef = collection(db, 'products')
  const categoriesCollectionRef = collection(db, 'categories')

  useEffect(() => {
    filterProducts()
  }, [selectedCategory])

  const filterProducts = () => {
    setFilteredProducts(
      products.filter((product) => {
        return selectedCategory !== null
          ? product.categories.includes(selectedCategory)
          : products
      }),
    )
  }
  // const onSearch = (e) => {
  //   setSearchValue(e.target.value)
  // }

  const onCategoryChange = (e) => {
    setSelectedCategory(e.target.innerText)
  }

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getDocs(productsCollectionRef)
        const filteredDocs = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setProducts(filteredDocs)
        setFilteredProducts(filteredDocs)
        setProductLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
    const fetchCategories = async () => {
      try {
        const data = await getDocs(categoriesCollectionRef)
        const filteredDocs = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setCategories(filteredDocs)
        setCategLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
    fetchCategories()
    fetchProducts()
  }, [])

  return (
    <div className="catalog-wrapper">
      {productLoading && categLoading ? (
        <Loader loading={productLoading && categLoading} />
      ) : (
        <>
          <div className="sidebar-wrapper">
            {/*<div className="sidebar-search">*/}
            {/*  <input*/}
            {/*    className="sidebar-search--input"*/}
            {/*    type="text"*/}
            {/*    placeholder={t('catalogPage.inputPlaceholder')}*/}
            {/*    value={searchValue}*/}
            {/*    onChange={onSearch}*/}
            {/*  />*/}
            {/*  <button className="sidebar-search--btn">*/}
            {/*    <img src={SearchIcon} alt={'search'} />*/}
            {/*  </button>*/}
            {/*</div>*/}
            <h2 className="sidebar-categories">
              {t('catalogPage.productCategories')}
            </h2>
            <div className="sidebar-categories-list">
              <button
                className="sidebar-categories-list--btn"
                onClick={() => setSelectedCategory(null)}
              >
                {t('catalogPage.productAllCategories')}
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1L6 6L1 11" stroke="#545454" />
                </svg>
              </button>
              {categories.map((category) => (
                <button
                  className="sidebar-categories-list--btn"
                  key={category.id}
                  onClick={onCategoryChange}
                >
                  {category.categoryName}
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 1L6 6L1 11" stroke="#545454" />
                  </svg>
                </button>
              ))}
            </div>
          </div>
          <div className="products-wrapper">
            {filteredProducts.length === 0 ? (
              <div className="not-found-product">
                <h1 className="not-found-product--title">
                  {t('catalogPage.notFoundTitle')}
                </h1>
                <span className="not-found-product--desc">
                  {t('catalogPage.notFoundDesc')}
                </span>
                <img src={NotFoundIcon} alt={'not-found'} />
              </div>
            ) : (
              filteredProducts.map((product, idx) => (
                <ProductCard key={idx} product={product} />
              ))
            )}
          </div>
        </>
      )}
    </div>
  )
}
