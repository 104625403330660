import { FirebaseAuthContext } from '../../context/auth'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './ForumPage.scss'
import ConversationImage from '../../assets/conversation.svg'
import CalendarImage from '../../assets/calendar.svg'
import Solar from '../../assets/solar.svg'
import Wind from '../../assets/wind.svg'
import CategArrowImage from '../../assets/categArrow.svg'
import Loader from '../../components/Loader/Loader'
import { useTranslation } from 'react-i18next'
import useFetchPosts from '../../hooks/useFetchPosts'
import useFetchPostsCount from '../../hooks/useFetchPostsCount'
import Pagination from '../../components/Pagination/Pagination'
import ForumHeader from '../../components/ForumHeader/ForumHeader'
import formatCreatedAt from '../../utils/formatCreatedAt'
import NotFoundIcon from '../../assets/notFoundIcon.svg'

const itemsPerPage = 20

export default function ForumPage() {
  const { t } = useTranslation('global')
  const { user } = useContext(FirebaseAuthContext)
  const [total, setTotal] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [page, setPage] = useState(1)
  const navigate = useNavigate()
  const cursors = useRef(new Map())
  const fetchPostCount = useFetchPostsCount()

  const { data, status } = useFetchPosts(searchValue, {
    cursor: cursors.current.get(page),
    itemsPerPage,
  })

  const posts = useMemo(() => {
    return data?.docs?.map((doc) => doc.data()) ?? []
  }, [data])

  const onPageChanged = useCallback(
    (nextPage) => {
      setPage((page) => {
        cursors.current.set(page + 1, data.docs[data.docs.length - 1])
        return nextPage
      })
    },
    [data],
  )

  const handleSearch = (search) => {
    setSearchValue(search)
  }

  useEffect(() => {
    fetchPostCount().then((result) => {
      setTotal(result.data().count)
    })
  }, [fetchPostCount])

  useEffect(() => {
    if (!user) {
      navigate('/sign-in', { replace: true })
    }
  }, [user])

  const totalPages = Math.ceil(total / itemsPerPage)

  const navigateToExternalLink = (externalLink) => {
    window.open(externalLink, '_blank'); // Open the external link in a new tab/window
  };

  return (
    <div className="forum-content">
      <ForumHeader handleSearch={handleSearch} />
      <div className="forum-info">
        <div className="forum-info--btn-error">
          <button onClick={() => navigateToExternalLink('https://protonenergysolutions-my.sharepoint.com/:f:/g/personal/info_protonenergy_md/Euj78pH5zr9DgSdGTeGuT4gB_eSwZ3S_3cBl7RrQHUdlxA?e=A6gFDq')}>{t('forum.buttons.documents')}</button>
          <button onClick={() => navigateToExternalLink('https://protonenergysolutions-my.sharepoint.com/:f:/g/personal/info_protonenergy_md/EgA8Z1SAxJ1LmTnutT-DarkBXmKg8qa6BOCJC4fB9ZqyuA?e=goqmIx')}>{t('forum.buttons.error_codes')}</button>
          <button onClick={() => navigate('/catalog')}>{t('forum.buttons.catalog')}</button>
        </div>
        <button
          className="forum-info--btn-ask"
          onClick={() => navigate('/forum/posts/create')}
        >
          <img src={ConversationImage} alt="" />
          {t('forum.createPost.askQuestionTitle')}
        </button>
      </div>
      <div className="forum-wrapper">
        {status === 'loading' ? (
          <Loader loading={true} />
        ) : (
          <>
            {posts.length === 0 ? (
              <div className="not-found-product">
                <h1 className="not-found-product--title">
                  {t('catalogPage.notFoundTitle')}
                </h1>
                <span className="not-found-product--desc">
                  {t('catalogPage.notFoundDesc')}
                </span>
                <img src={NotFoundIcon} alt={'not-found'} />
              </div>
            ) : (
              posts.map((post) => (
                <Link
                  to={`/forum/posts/${post.id}`}
                  key={post.id}
                  className="forum-item"
                >
                  <div className="name-date-energy">
                    <h2>{post.title}</h2>
                    <div className="date-energy">
                      <p>
                        <img src={CalendarImage} alt="calendar" />{' '}
                        {formatCreatedAt(post.createdAt)}
                      </p>
                      <p className="energy-type">
                        <span>{t(`forum.${post.energyType}`)}</span>
                        <img
                          src={post.energyType === 'solar' ? Solar : Wind}
                          alt="Energy type icon"
                        />
                      </p>
                    </div>
                  </div>
                  <div className="post-number">
                    <div className="number">
                      <p>{post.commentsNumber}</p> <span>{t('forum.post.replies')}</span>
                    </div>
                    <img src={CategArrowImage} alt="" />
                  </div>
                </Link>
              ))
            )}
          </>
        )}
      </div>
      <Pagination
        page={page}
        onPageChanged={onPageChanged}
        totalPages={totalPages}
      />
    </div>
  )
}
