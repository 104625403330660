import { createContext, useContext, useEffect, useState } from 'react'
import { auth, db } from '../config/firebase'
import { doc, getDoc } from 'firebase/firestore'
import { signOut } from 'firebase/auth'

const FirebaseAuthContext = createContext(undefined)

const FirebaseAuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    return auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.email))
          const userData = await userDoc.data()
          if (!userData?.disabled) {
            setUser({
              firstName: userData.firstName,
              lastName: userData.lastName,
              companyName: userData.companyName,
              email: user.email,
              disabled: userData.disabled,
            })
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        setUser(null)
      }
      setLoading(false)
    })
  }, [])

  const logOut = async () => {
    await signOut(auth)
    setUser(null)
  }

  const value = { user, loading, logOut }

  return (
    <FirebaseAuthContext.Provider value={value}>
      {children}
    </FirebaseAuthContext.Provider>
  )
}

function useFirebaseAuth() {
  const context = useContext(FirebaseAuthContext)
  if (context === undefined) {
    throw new Error(
      'useFirebaseAuth must be used within a FirebaseAuthProvider',
    )
  }
  return context.user
}

export { FirebaseAuthContext, FirebaseAuthProvider, useFirebaseAuth }
