import { Slide } from 'react-slideshow-image'
import './ProductItemSlider.scss'

export const ProductItemSlider = ({ images }) => {
  return (
    <div className="product-item-slider">
      <Slide
        slidesToScroll={1}
        arrows={false}
        autoplay={false}
        indicators={true}
        transitionDuration={300}
      >
        {images?.map((image, idx) => (
          <img
            className="product-item-slider--img"
            key={idx}
            src={image}
            alt="product-image"
          />
        ))}
      </Slide>
    </div>
  )
}
