import './Footer.scss'
import { useTranslation } from 'react-i18next'
import AddressIcon from '../../assets/adressIcon.svg'
import PhoneIcon from '../../assets/phoneIcon.svg'
import EmailIcon from '../../assets/emailIcon.svg'
import PoweredByIcon from '../../assets/poweredBy.svg'
import { Link } from 'react-router-dom'

export default function Footer() {
  const { t } = useTranslation('global')

  return (
    <div className="footer-wrapper">
      <div className="footer-content">
        <div className="footer-content--column-desc">
          <div className="footer-content--column-desc-title">
            ©2023 PROTON ENERGY
          </div>
          <div className="footer-content--column-desc-text">
            {t('footer.mission')}
          </div>
          <div className="footer-content--column-desc-buttons">
            <button className="footer-content--column-desc-buttons-btn">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 30C11.0218 30 7.20645 28.4196 4.39341 25.6066C1.58036 22.7936 7.50044e-06 18.9782 7.50044e-06 15V15C-0.00385489 11.1352 1.48414 7.41812 4.15377 4.62357C6.82341 1.82903 10.4686 0.172741 14.3296 0V0H15C16.9698 0 18.9204 0.387988 20.7403 1.14181C22.5601 1.89563 24.2137 3.00052 25.6066 4.3934C26.9995 5.78628 28.1044 7.43986 28.8582 9.25975C29.612 11.0796 30 13.0302 30 15V15C30 16.9698 29.612 18.9204 28.8582 20.7403C28.1044 22.5601 26.9995 24.2137 25.6066 25.6066C24.2137 26.9995 22.5601 28.1044 20.7403 28.8582C18.9204 29.612 16.9698 30 15 30V30ZM5.15152 24.8636C6.77291 26.4866 8.76952 27.6847 10.9645 28.3518C13.1595 29.0188 15.4851 29.1343 17.7354 28.6879C19.9856 28.2416 22.0911 27.2472 23.8653 25.7928C25.6395 24.3384 27.0276 22.4689 27.9068 20.3499C28.7859 18.231 29.1289 15.9279 28.9054 13.6447C28.6819 11.3615 27.8988 9.16865 26.6255 7.26037C25.3521 5.3521 23.6278 3.78729 21.6053 2.70455C19.5827 1.62181 17.3244 1.05456 15.0303 1.05303V1.05303C13.1986 1.05253 11.3848 1.41294 9.69245 2.11367C8.0001 2.81439 6.4624 3.8417 5.16721 5.13689C3.87201 6.43209 2.8447 7.96979 2.14398 9.66214C1.44325 11.3545 1.08284 13.1683 1.08334 15C1.07707 16.8299 1.43183 18.643 2.12732 20.3355C2.8228 22.0281 3.84534 23.5668 5.13637 24.8636H5.15152Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.0834 7.72351H11.9167C10.8052 7.72551 9.73982 8.16793 8.95388 8.95387C8.16794 9.73981 7.72551 10.8052 7.72351 11.9167V18.0833C7.72551 19.1948 8.16794 20.2602 8.95388 21.0462C9.73982 21.8321 10.8052 22.2745 11.9167 22.2765H18.0834C19.1948 22.2745 20.2602 21.8321 21.0462 21.0462C21.8321 20.2602 22.2745 19.1948 22.2765 18.0833V11.9167C22.2745 10.8052 21.8321 9.73981 21.0462 8.95387C20.2602 8.16793 19.1948 7.72551 18.0834 7.72351V7.72351ZM20.7917 18.0833C20.7907 18.8013 20.505 19.4896 19.9973 19.9973C19.4896 20.505 18.8013 20.7907 18.0834 20.7917H11.9167C11.1987 20.7907 10.5104 20.505 10.0027 19.9973C9.49502 19.4896 9.20936 18.8013 9.20836 18.0833V11.9167C9.20936 11.1987 9.49502 10.5104 10.0027 10.0027C10.5104 9.49502 11.1987 9.20935 11.9167 9.20835H18.0834C18.8013 9.20935 19.4896 9.49502 19.9973 10.0027C20.505 10.5104 20.7907 11.1987 20.7917 11.9167V18.0833Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 11.6212C14.3317 11.6212 13.6785 11.8194 13.1229 12.1906C12.5672 12.5619 12.1341 13.0896 11.8784 13.707C11.6227 14.3244 11.5558 15.0038 11.6861 15.6592C11.8165 16.3146 12.1383 16.9166 12.6108 17.3892C13.0834 17.8617 13.6854 18.1835 14.3408 18.3139C14.9963 18.4442 15.6756 18.3773 16.293 18.1216C16.9104 17.8659 17.4381 17.4328 17.8094 16.8772C18.1806 16.3215 18.3788 15.6683 18.3788 15C18.3778 14.1042 18.0215 13.2454 17.3881 12.612C16.7546 11.9785 15.8958 11.6222 15 11.6212ZM15 16.8258C14.6389 16.8258 14.2859 16.7187 13.9857 16.5181C13.6854 16.3175 13.4514 16.0323 13.3132 15.6987C13.175 15.3651 13.1389 14.998 13.2093 14.6438C13.2798 14.2897 13.4537 13.9643 13.709 13.709C13.9643 13.4537 14.2897 13.2798 14.6438 13.2093C14.998 13.1389 15.3651 13.175 15.6987 13.3132C16.0323 13.4514 16.3174 13.6854 16.5181 13.9857C16.7187 14.2859 16.8258 14.6389 16.8258 15C16.8258 15.4842 16.6334 15.9486 16.291 16.291C15.9486 16.6334 15.4842 16.8258 15 16.8258V16.8258Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.7879 10.1553C18.5892 10.1545 18.3947 10.2128 18.2291 10.3226C18.0636 10.4324 17.9343 10.589 17.8578 10.7723C17.7812 10.9557 17.7608 11.1576 17.7991 11.3526C17.8374 11.5475 17.9327 11.7267 18.0729 11.8675C18.2132 12.0083 18.392 12.1042 18.5868 12.1433C18.7817 12.1823 18.9837 12.1627 19.1673 12.0868C19.351 12.0109 19.508 11.8823 19.6184 11.7171C19.7289 11.552 19.7879 11.3578 19.7879 11.1591C19.7879 10.8935 19.6826 10.6388 19.4952 10.4506C19.3078 10.2625 19.0534 10.1563 18.7879 10.1553V10.1553Z"
                />
              </svg>
            </button>
            <button className="footer-content--column-desc-buttons-btn">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 30C11.0218 30 7.20645 28.4196 4.3934 25.6066C1.58036 22.7936 7.50044e-06 18.9782 7.50044e-06 15V15C-0.00385489 11.1352 1.48414 7.41812 4.15377 4.62357C6.82341 1.82903 10.4686 0.172741 14.3295 0V0H15C18.9783 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15V15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9783 30 15 30V30ZM5.15152 24.8636C6.77336 26.4871 8.77063 27.6853 10.9663 28.3523C13.162 29.0193 15.4883 29.1343 17.7391 28.6872C19.9899 28.2401 22.0956 27.2447 23.8697 25.7892C25.6438 24.3337 27.0314 22.463 27.9097 20.343C28.7879 18.2229 29.1296 15.919 28.9045 13.6353C28.6794 11.3516 27.8944 9.15869 26.6192 7.25092C25.3439 5.34315 23.6177 3.77942 21.5936 2.6983C19.5695 1.61718 17.3099 1.05207 15.0152 1.05303C13.1822 1.05054 11.3667 1.40948 9.67264 2.10931C7.97854 2.80914 6.43905 3.83614 5.14225 5.13153C3.84545 6.42692 2.81679 7.96529 2.11512 9.65863C1.41345 11.352 1.05254 13.167 1.05304 15V15C1.05075 16.832 1.41029 18.6464 2.11101 20.339C2.81174 22.0317 3.83986 23.5693 5.13637 24.8636H5.15152Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.0073 7.67426L7.81413 14.1364C7.61616 14.2498 7.45501 14.4179 7.3499 14.6204C7.24479 14.8229 7.20015 15.0514 7.22131 15.2786C7.24248 15.5058 7.32854 15.7221 7.46925 15.9017C7.60996 16.0814 7.79937 16.2167 8.01489 16.2917L10.9846 17.3258L17.7005 12.0644L13.0414 18.0417V21.519C13.0418 21.6004 13.066 21.6799 13.1111 21.7477C13.1562 21.8155 13.2202 21.8686 13.2952 21.9005C13.3701 21.9323 13.4528 21.9415 13.5329 21.9269C13.613 21.9122 13.6871 21.8745 13.7459 21.8182L15.4922 20.1477L17.7194 22.178C17.8843 22.3236 18.0862 22.4207 18.3029 22.4587C18.5195 22.4967 18.7424 22.4741 18.947 22.3934C19.1516 22.3126 19.3299 22.1769 19.4622 22.0012C19.5945 21.8255 19.6756 21.6167 19.6967 21.3977L20.78 8.80683C20.7966 8.59157 20.7544 8.37584 20.6581 8.18265C20.5617 7.98945 20.4147 7.82603 20.2328 7.70979C20.0508 7.59355 19.8408 7.52885 19.6249 7.5226C19.4091 7.51634 19.1957 7.56876 19.0073 7.67426V7.67426Z"
                />
              </svg>
            </button>
            <button className="footer-content--column-desc-buttons-btn">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 30C11.0218 30 7.20645 28.4196 4.3934 25.6066C1.58036 22.7936 7.50044e-06 18.9782 7.50044e-06 15V15C-0.00385489 11.1352 1.48414 7.41811 4.15377 4.62357C6.8234 1.82903 10.4686 0.172741 14.3295 0V0H15C16.9698 0 18.9204 0.387988 20.7403 1.14181C22.5601 1.89563 24.2137 3.00052 25.6066 4.3934C26.9995 5.78628 28.1044 7.43986 28.8582 9.25975C29.612 11.0796 30 13.0302 30 15V15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30V30ZM5.15152 24.8636C6.77291 26.4866 8.76952 27.6847 10.9645 28.3517C13.1595 29.0188 15.4851 29.1343 17.7354 28.6879C19.9856 28.2416 22.0911 27.2471 23.8653 25.7928C25.6395 24.3384 27.0276 22.4689 27.9067 20.3499C28.7859 18.231 29.1289 15.9279 28.9054 13.6447C28.6819 11.3615 27.8988 9.16865 26.6254 7.26037C25.3521 5.3521 23.6278 3.78729 21.6053 2.70455C19.5827 1.62181 17.3244 1.05456 15.0303 1.05303V1.05303C13.1986 1.05253 11.3848 1.41294 9.69244 2.11367C8.0001 2.81439 6.46239 3.8417 5.1672 5.13689C3.872 6.43209 2.84469 7.96979 2.14397 9.66214C1.44325 11.3545 1.08284 13.1683 1.08333 15C1.07707 16.8299 1.43183 18.643 2.12731 20.3355C2.82279 22.0281 3.84533 23.5668 5.13637 24.8636H5.15152Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.0037 7.07952L16.6666 7.02648C16.2111 7.01634 15.7582 7.0976 15.3347 7.26545C14.9112 7.43331 14.5256 7.68436 14.2007 8.00376C13.8726 8.32092 13.6122 8.70125 13.4351 9.1218C13.258 9.54235 13.168 9.99442 13.1704 10.4507V12.6628H10.9848C10.9416 12.6628 10.9002 12.68 10.8696 12.7105C10.8391 12.7411 10.8219 12.7825 10.8219 12.8257V15.6098C10.8219 15.631 10.8261 15.652 10.8344 15.6716C10.8426 15.6911 10.8546 15.7088 10.8698 15.7237C10.885 15.7385 10.903 15.7501 10.9227 15.7579C10.9425 15.7657 10.9636 15.7694 10.9848 15.7689H13.1552V22.8106C13.1552 22.8538 13.1724 22.8952 13.2029 22.9257C13.2335 22.9563 13.2749 22.9734 13.3181 22.9734H16.1893C16.2325 22.9734 16.274 22.9563 16.3045 22.9257C16.335 22.8952 16.3522 22.8538 16.3522 22.8106V15.7689H18.5605C18.6001 15.7693 18.6384 15.7553 18.6684 15.7295C18.6983 15.7037 18.7179 15.6679 18.7234 15.6288L19.0833 12.8447C19.0867 12.8223 19.085 12.7995 19.0785 12.7779C19.0719 12.7562 19.0606 12.7363 19.0454 12.7197C19.0302 12.7023 19.0116 12.6883 18.9907 12.6786C18.9699 12.6688 18.9472 12.6634 18.9242 12.6628H16.3749V11.2575C16.3393 10.875 16.4443 10.4925 16.6704 10.1818C16.7586 10.0902 16.8655 10.0188 16.9838 9.97238C17.1021 9.92597 17.2291 9.90566 17.356 9.91285H19.0075C19.0287 9.91336 19.0498 9.90962 19.0695 9.90185C19.0893 9.89408 19.1073 9.88243 19.1224 9.86761C19.1376 9.85278 19.1497 9.83508 19.1579 9.81553C19.1661 9.79598 19.1704 9.77497 19.1704 9.75376V7.24239C19.1709 7.22053 19.167 7.1988 19.1587 7.17854C19.1505 7.15828 19.1382 7.13993 19.1225 7.12464C19.1069 7.10936 19.0883 7.09746 19.0678 7.0897C19.0474 7.08193 19.0256 7.07847 19.0037 7.07952Z"
                />
              </svg>
            </button>
            <button className="footer-content--column-desc-buttons-btn">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 30C13.0302 30 11.0796 29.612 9.25975 28.8582C7.43987 28.1044 5.78628 26.9995 4.3934 25.6066C3.00053 24.2137 1.89564 22.5601 1.14182 20.7402C0.387995 18.9204 7.50044e-06 16.9698 7.50044e-06 15V15C-0.00385489 11.1352 1.48414 7.41811 4.15377 4.62357C6.8234 1.82903 10.4686 0.172741 14.3295 0V0H15C16.9698 0 18.9204 0.387988 20.7403 1.14181C22.5601 1.89563 24.2137 3.00052 25.6066 4.3934C26.9995 5.78628 28.1044 7.43986 28.8582 9.25975C29.612 11.0796 30 13.0302 30 15V15C30 16.9698 29.612 18.9204 28.8582 20.7402C28.1044 22.5601 26.9995 24.2137 25.6066 25.6066C24.2137 26.9995 22.5601 28.1044 20.7403 28.8582C18.9204 29.612 16.9698 30 15 30V30ZM5.15152 24.8636C6.77291 26.4866 8.76952 27.6847 10.9645 28.3517C13.1595 29.0188 15.4851 29.1343 17.7354 28.6879C19.9856 28.2416 22.0911 27.2471 23.8653 25.7928C25.6395 24.3384 27.0276 22.4689 27.9067 20.3499C28.7859 18.231 29.1289 15.9279 28.9054 13.6447C28.6819 11.3615 27.8988 9.16865 26.6254 7.26037C25.3521 5.3521 23.6278 3.78729 21.6053 2.70455C19.5827 1.62181 17.3244 1.05456 15.0303 1.05303V1.05303C13.1986 1.05253 11.3848 1.41294 9.69244 2.11367C8.0001 2.81439 6.46239 3.8417 5.1672 5.13689C3.872 6.43209 2.84469 7.96979 2.14397 9.66214C1.44325 11.3545 1.08284 13.1683 1.08333 15C1.07707 16.8299 1.43183 18.643 2.12731 20.3355C2.82279 22.0281 3.84533 23.5668 5.13637 24.8636H5.15152Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.0038 8.80688H10C9.13569 8.80688 8.30673 9.14997 7.69521 9.76078C7.0837 10.3716 6.73965 11.2002 6.73865 12.0645V17.9357C6.73965 18.8 7.0837 19.6285 7.69521 20.2394C8.30673 20.8502 9.13569 21.1932 10 21.1932H20.0038C20.8678 21.1932 21.6963 20.85 22.3072 20.2391C22.9182 19.6282 23.2614 18.7996 23.2614 17.9357V12.0645C23.2614 11.2005 22.9182 10.3719 22.3072 9.76101C21.6963 9.1501 20.8678 8.80688 20.0038 8.80688V8.80688ZM12.9659 17.716V12.2842C12.9661 12.1864 12.9922 12.0905 13.0415 12.0061C13.0908 11.9217 13.1616 11.8519 13.2467 11.8038C13.3318 11.7557 13.4281 11.731 13.5258 11.7322C13.6235 11.7334 13.7192 11.7605 13.803 11.8107L18.3485 14.5266C18.431 14.5751 18.4994 14.6443 18.5469 14.7273C18.5944 14.8104 18.6194 14.9044 18.6194 15.0001C18.6194 15.0957 18.5944 15.1898 18.5469 15.2728C18.4994 15.3559 18.431 15.4251 18.3485 15.4736L13.803 18.1895C13.7194 18.2409 13.6235 18.269 13.5253 18.2709C13.4271 18.2727 13.3302 18.2481 13.2447 18.1998C13.1592 18.1514 13.0883 18.081 13.0392 17.996C12.9902 17.9109 12.9649 17.8142 12.9659 17.716Z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="footer-content--column">
          <div className="footer-content--column-title">
            {t('footer.pages')}
          </div>
          <ul className="footer-content--column-pages-links">
            <li>
              <Link className="footer-content--column-links-link" to={'#'}>
                {t('footer.forum')}
              </Link>
            </li>
            <li>
              <Link className="footer-content--column-links-link" to={'#'}>
                {t('footer.news')}
              </Link>
            </li>
            <li>
              <Link
                className="footer-content--column-links-link"
                to={'/catalog'}
              >
                {t('footer.catalog')}
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-content--column">
          <div className="footer-content--column-title">
            {t('footer.contacts')}
          </div>
          <div className="footer-content--column-links">
            <div>
              <img src={AddressIcon} alt="address" />
              <a
                className="footer-content--column-links-link"
                target="_blank"
                href="https://www.google.com/maps/place/Liceul+teoretic+Nicolae+Tretiacov,+Strada+Lenin+144,+Comrat,+%D0%9C%D0%BE%D0%BB%D0%B4%D0%BE%D0%B2%D0%B0/@46.3086026,28.6535334,17z/data=!3m1!4b1!4m6!3m5!1s0x40c9df53d26750e3:0xd0ac73eebd53ea8b!8m2!3d46.3085989!4d28.6561083!16s%2Fg%2F11gmz7xdpr?entry=ttu"
                rel="noreferrer"
              >
                г.Комрат ул.Ленина 144
              </a>
            </div>
            <div>
              <img src={PhoneIcon} alt="phone" />
              <a
                className="footer-content--column-links-link"
                href="tel: +373-68-459-705"
              >
                +373-68-459-705
              </a>
            </div>
            <div>
              <img src={EmailIcon} alt="email" />
              <a
                className="footer-content--column-links-link"
                href="mailto: info@protonenergy.md"
              >
                info@protonenergy.md
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <a
          href={'https://sintech.dev/'}
          target={'_blank'}
          className="powered-by"
          rel="noreferrer"
        >
          <img src={PoweredByIcon} alt="sintech" />
        </a>
        <div className="policy">
          <Link className="policy-link" to={'/privacy-policy'}>
            {t('footer.privacy')}
          </Link>
          {/*<Link className="policy-link" to={'#'}>*/}
          {/*  {t('footer.terms')}*/}
          {/*</Link>*/}
        </div>
      </div>
    </div>
  )
}
