import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './SignUpPage.scss'
import { auth, db } from '../../config/firebase'
import { doc, setDoc } from 'firebase/firestore'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader/Loader'
import { FirebaseAuthContext } from '../../context/auth'
import ForumHeader from '../../components/ForumHeader/ForumHeader'

// const emailRe = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

export default function SignUpPage() {
  const { user, loading } = useContext(FirebaseAuthContext)
  const { t } = useTranslation('global')
  let navigate = useNavigate()
  const [error, setError] = useState(null)
  const [dataErrors, setDataErrors] = useState({
    email: true,
    password: true,
    rePassword: true,
    firstName: true,
    lastName: true,
    companyName: true,
    intro: true,
  })
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    rePassword: '',
    firstName: '',
    lastName: '',
    companyName: '',
    intro: '',
  })

  const signUp = async () => {
    try {
      await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password,
      )
      await setDoc(doc(db, 'users', formData.email), {
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        companyName: formData.companyName,
        intro: formData.intro,
        disabled: true,
      })
      navigate('/sign-in', { replace: true })
    } catch (e) {
      switch (e.code) {
        case 'auth/email-already-in-use':
          setError(t('auth.errors.emailTaken'))
      }
      console.log(e.code)
    }
  }

  const validateEmail = () => {
    if (!formData.email.includes('@')) {
      setDataErrors({ ...dataErrors, email: true })
    } else {
      setDataErrors({ ...dataErrors, email: false })
    }
  }

  const validatePassword = (password, passwordField) => {
    const otherPasswordField =
      passwordField === 'password' ? 'rePassword' : 'password'
    if (password.length < 6 || password !== formData[otherPasswordField]) {
      setDataErrors({ ...dataErrors, password: true, rePassword: true })
    } else {
      setDataErrors({ ...dataErrors, password: false, rePassword: false })
    }
  }

  const validateText = (fieldName, value) => {
    if (value.length < 1) {
      setDataErrors({ ...dataErrors, [fieldName]: true })
    } else {
      setDataErrors({ ...dataErrors, [fieldName]: false })
    }
  }

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case 'email': {
        validateEmail(value)
        break
      }
      case 'password': {
        validatePassword(value, 'password')
        break
      }
      case 'rePassword': {
        validatePassword(value, 'rePassword')
        break
      }
      default: {
        validateText(fieldName, value)
      }
    }
  }

  const handleChange = (e) => {
    const value = e.target.value
    setFormData({
      ...formData,
      [e.target.name]: value,
    })
    validateField(e.target.name, value)
  }

  useEffect(() => {
    if (user) {
      navigate('/forum', { replace: true })
    }
  }, [user, loading])

  return (
    <>
      <ForumHeader />
      <div className="content">
        <div className="content--content-title">
          <h1>{t('auth.signInTitle')}</h1>
          <p>{t('auth.signInText')}</p>
        </div>
        {loading ? (
          <Loader loading={true} />
        ) : (
          <div className="content--content-wrapper">
            {error && (
              <h3 className="content--content-wrapper--error">{error}</h3>
            )}
            <form className="sign-up-form">
              <input
                className={dataErrors.email ? 'invalid' : null}
                placeholder={t('auth.emailPlaceholder')}
                value={formData.email}
                name="email"
                type="email"
                onChange={handleChange}
              />
              <input
                className={dataErrors.password ? 'invalid' : null}
                placeholder={t('auth.passwordPlaceholder')}
                value={formData.password}
                name="password"
                type="password"
                onChange={handleChange}
              />
              <input
                className={dataErrors.rePassword ? 'invalid' : null}
                placeholder={t('auth.rePasswordPlaceholder')}
                value={formData.rePassword}
                name="rePassword"
                type="password"
                onChange={handleChange}
              />
              <input
                className={dataErrors.firstName ? 'invalid' : null}
                placeholder={t('auth.firstName')}
                value={formData.firstName}
                name="firstName"
                type="text"
                onChange={handleChange}
              />
              <input
                className={dataErrors.lastName ? 'invalid' : null}
                placeholder={t('auth.lastName')}
                value={formData.lastName}
                name="lastName"
                type="text"
                onChange={handleChange}
              />
              <input
                className={dataErrors.companyName ? 'invalid' : null}
                placeholder={t('auth.companyName')}
                value={formData.companyName}
                name="companyName"
                type="text"
                onChange={handleChange}
              />
              <input
                className={dataErrors.intro ? 'invalid' : null}
                placeholder={t('auth.intro')}
                value={formData.intro}
                name="intro"
                type="text"
                onChange={handleChange}
              />
              <button
                type="button"
                disabled={Object.values(dataErrors).some((item) => item)}
                onClick={async () => {
                  await signUp()
                }}
              >
                {t('auth.signUpButton')}
              </button>
            </form>
          </div>
        )}
      </div>
    </>
  )
}
