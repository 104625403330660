import './NewsItemPage.scss'
import { useEffect, useState } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../config/firebase'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader/Loader'
import DateIcon from '../../assets/dateIcon.svg'

export default function NewsItemPage() {
  const { newsId } = useParams()
  const { i18n } = useTranslation('global')
  const [newsItem, setNewsItem] = useState(null)
  const newsCollectionItemRef = doc(db, 'news', newsId)

  useEffect(() => {
    const fetchNewsItem = async () => {
      try {
        const doc = await getDoc(newsCollectionItemRef)
        setNewsItem(doc.data())
      } catch (err) {
        console.log(err)
      }
    }

    fetchNewsItem()
  }, [newsId])

  if (newsItem) {
    return (
      <div className="news-page">
        <div className="container">
          <div className="container--main-news">
            <img
              src={newsItem.imageURL}
              alt="News item image"
              className="container--main-news--image"
            />
            <p className="container--date">
              <img src={DateIcon} alt="date" className="icon" />
              {new Date(newsItem.createdAt.seconds * 1000).toLocaleDateString()}
            </p>
            <h1>{newsItem.title[i18n.resolvedLanguage]}</h1>
            <div className="container--main-news--text">
              <p>{newsItem.text[i18n.resolvedLanguage]}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="news-page--loader">
      <Loader loading={true} />
    </div>
  )
}
