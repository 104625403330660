import { Link, useParams } from 'react-router-dom'
import './PostPage.scss'
import ChatImage from '../../assets/chat.svg'
import BackArrow from '../../assets/backArrow.svg'
import CalendarImage from '../../assets/calendar.svg'
import Solar from '../../assets/solar.svg'
import Wind from '../../assets/wind.svg'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  collection,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  increment,
  serverTimestamp,
} from 'firebase/firestore'
import { db } from '../../config/firebase'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader/Loader'
import useFetchCommentsCount from '../../hooks/useFetchCommentsCount'
import useFetchComments from '../../hooks/useFetchComments'
import CommentItem from '../../components/CommentItem/CommentItem'
import Pagination from '../../components/Pagination/Pagination'
import { FirebaseAuthContext } from '../../context/auth'
import ForumHeader from '../../components/ForumHeader/ForumHeader'

const itemsPerPage = 20

export default function PostPage() {
  const { t } = useTranslation('global')
  const { postId } = useParams()
  const [commentInput, setCommentInput] = useState('')
  const [totalComments, setTotalComments] = useState(0)
  const { user } = useContext(FirebaseAuthContext)

  const [page, setPage] = useState(1)
  const cursors = useRef(new Map())
  const [postItem, setPostItem] = useState(null)
  const postCollectionItemRef = doc(db, 'posts', postId)
  const fetchCommentsCount = useFetchCommentsCount()
  const totalPages = Math.ceil(totalComments / itemsPerPage)

  useEffect(() => {
    const fetchPostItem = async () => {
      try {
        const doc = await getDoc(postCollectionItemRef)
        setPostItem(doc.data())
      } catch (err) {
        console.log(err)
      }
    }
    fetchPostItem()
  }, [postId])

  useEffect(() => {
    fetchCommentsCount(postId).then((result) => {
      setTotalComments(result.data().count)
    })
  }, [fetchCommentsCount, postId])

  const { data, status } = useFetchComments(postId, {
    cursor: cursors.current.get(page),
    itemsPerPage,
  })

  const comments = useMemo(() => {
    return data?.docs?.map((doc) => doc.data()) ?? []
  }, [data, postId])

  const onPageChanged = useCallback(
    (nextPage) => {
      setPage((page) => {
        cursors.current.set(page + 1, data.docs[data.docs.length - 1])
        return nextPage
      })
    },
    [data],
  )

  const submitForm = async (e) => {
    e.preventDefault()
    if (commentInput) {
      await sendComment()
      await updatePost()
      setCommentInput('')
    }
  }

  const updatePost = async () => {
    const postRef = doc(db, 'posts', postId)
    await updateDoc(postRef, {
      commentsNumber: increment(1),
    })
  }

  const sendComment = async () => {
    await addDoc(collection(db, 'comments'), {
      body: commentInput,
      postId,
      createdAt: serverTimestamp(),
      author: {
        fullName: `${user.firstName} ${user.lastName}`,
        companyName: user.companyName,
      },
    })
  }

  return (
    <div className="post">
      <ForumHeader />
      {postItem ? (
        <div className="post-content">
          <div className="post-content--header">
            <div className="post-content--header--left">
              <Link to="/forum">
                <img src={BackArrow} alt="Back button" />
              </Link>
              <p className="post-content--header--left--title">
                {postItem.title}
              </p>
            </div>
            <div className="post-content--header--right">
              <div className="post-content--header--right--container">
                <img src={CalendarImage} alt="Calendar image" />
                <p>
                  {new Date(
                    postItem.createdAt.seconds * 1000,
                  ).toLocaleDateString('ru-RU')}
                </p>
              </div>
              <div className="post-content--header--right--container">
                <img
                  src={postItem.energyType === 'solar' ? Solar : Wind}
                  alt="Energy type image"
                />
                <p>{t(`forum.${postItem.energyType}`)}</p>
              </div>
            </div>
          </div>
          <p className="post-content--body">{postItem.body}</p>
          <form className="post-content--input" onSubmit={submitForm}>
            <textarea
              value={commentInput}
              onChange={(e) => setCommentInput(e.target.value)}
            />

            <button className="post-content--input--send-button" type="submit">
              <img src={ChatImage} alt={'Send comment image'} />
              {t('forum.post.postItem.sendComment')}
            </button>
          </form>
          <div className="post-content--comments">
            {status === 'loading' ? (
              <Loader loading={true} />
            ) : (
              <>
                {comments.map((comment) => (
                  <CommentItem key={comment.id} comment={comment} />
                ))}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="post-content--loader" onClick={() => setPage(2)}>
          <Loader loading={true} />
        </div>
      )}
      {totalPages && (
        <Pagination
          page={page}
          onPageChanged={onPageChanged}
          totalPages={totalPages}
        />
      )}
    </div>
  )
}
