import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Select from '../../shared/ui/Select/Select'
import Logo from '../../assets/Logo_white_new.png'
import './Header.scss'

const LangOptions = [
  { value: 'ru', content: 'RU' },
  { value: 'ro', content: 'RO' },
]

export default function Header() {
  const [t, i18n] = useTranslation('global')
  let location = useLocation()

  const onChangeLang = useCallback(
    (lang) => {
      i18n.changeLanguage(lang)
    },
    [i18n],
  )

  const [navbar, setNavbar] = useState(false)

  const changeBackground = () => {
    if (window.pageYOffset > window.innerHeight * 0.05) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground)
  })

  const navRef = useRef()

  const showNavbar = () => {
    navRef.current.classList.toggle('header-navigation-responsive')
  }

  return (
    <div
      className={
        navbar ? 'header-wrapper header-wrapper-active' : 'header-wrapper'
      }
      style={
        location.pathname === '/'
          ? { background: 'transparent' }
          : { background: 'rgba(0, 0, 0, 0.7)' }
      }
    >
      <Link to={'/'}>
        <img className="header-logo" alt="logo" src={Logo} />
      </Link>
      <nav className="header-navigation" ref={navRef}>
        <NavLink
          onClick={showNavbar}
          className="header-navigation--link"
          to={'/'}
        >
          {t('header.mainTab')}
        </NavLink>
        <NavLink
          onClick={showNavbar}
          className="header-navigation--link"
          to={'/news'}
        >
          {t('header.newsTab')}
        </NavLink>
        <NavLink
          onClick={showNavbar}
          className="header-navigation--link"
          to={'/forum'}
        >
          {t('header.forumTab')}
        </NavLink>
        <NavLink
          onClick={showNavbar}
          className="header-navigation--link"
          to={'/catalog'}
        >
          {t('header.storeTab')}
        </NavLink>
        <Select
          options={LangOptions}
          onChange={onChangeLang}
          value={i18n.resolvedLanguage}
        />
        <button
          className="header-navigation-btn header-navigation-close-btn"
          onClick={showNavbar}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="8.08829"
              y1="8.00781"
              x2="21.356"
              y2="21.2756"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <line
              x1="21.3562"
              y1="8.08804"
              x2="8.08846"
              y2="21.3558"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </nav>
      <button className="header-navigation-btn" onClick={showNavbar}>
        <svg
          width="30"
          height="27"
          viewBox="0 0 30 27"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="30" height="4" rx="2" />
          <rect y="11" width="30" height="4" rx="2" />
          <rect y="23" width="30" height="4" rx="2" />
        </svg>
      </button>
    </div>
  )
}
