import './CreatePostPage.scss'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { db } from '../../config/firebase'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import ForumHeader from '../../components/ForumHeader/ForumHeader'

export default function CreatePostPage() {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    errorCode: '',
    title: '',
    questionType: 'solar',
    body: '',
    agreePrivacyPolicy: 'false',
  })
  const [dataErrors, setDataErrors] = useState({
    errorCode: true,
    title: true,
    questionType: false,
    body: true,
    agreePrivacyPolicy: true,
  })
  const { t } = useTranslation('global')

  const handleChange = (e) => {
    const value =
      e.target.name !== 'agreePrivacyPolicy' ? e.target.value : e.target.checked
    setFormData({
      ...formData,
      [e.target.name]: value,
    })
    validateField(e.target.name, value)
  }

  const validateCheckbox = (value) => {
    setDataErrors({ ...dataErrors, agreePrivacyPolicy: !value })
  }

  const validateText = (fieldName, value) => {
    if (value.length < 1) {
      setDataErrors({ ...dataErrors, [fieldName]: true })
    } else {
      setDataErrors({ ...dataErrors, [fieldName]: false })
    }
  }

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case 'agreePrivacyPolicy': {
        validateCheckbox(value)
        break
      }
      default: {
        validateText(fieldName, value)
      }
    }
  }

  const createPost = async (e) => {
    e.preventDefault()
    try {
      const title = `${formData.errorCode} | ${formData.title}`
      await addDoc(collection(db, 'posts'), {
        title: title,
        energyType: formData.questionType,
        body: formData.body,
        commentsNumber: 0,
        createdAt: serverTimestamp(),
      })
      navigate('/forum', { replace: true })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <ForumHeader />
      <div className="contact-section">
        <div className="supporting-text">
          <h1>{t('forum.createPost.askQuestionTitle')}</h1>
          <p>
            {t('forum.createPost.askQuestionDescription')}
            <br />
            {t('forum.createPost.askQuestionDescription2')}
          </p>
        </div>

        <form id="form" onSubmit={async (e) => await createPost(e)}>
          {/*Details*/}
          <div className="form-control">
            <label htmlFor="name" id="label-name">
              {t('forum.createPost.errorCodeLabel')}
            </label>
            {/*Input Type Text*/}
            <input
              name="errorCode"
              className={dataErrors.errorCode ? 'invalid' : null}
              value={formData.errorCode}
              onChange={handleChange}
              type="text"
              id="error-code"
              placeholder="365256"
            />
          </div>
          <div className="form-control">
            <label htmlFor="name" id="label-name">
              {t('forum.createPost.postTitleLabel')}
            </label>
            {/*Input Type Text*/}
            <input
              name="title"
              className={dataErrors.title ? 'invalid' : null}
              value={formData.title}
              onChange={handleChange}
              type="text"
              id="heading"
              placeholder={t('forum.createPost.titlePlaceholder')}
            />
          </div>
          <div className="form-control">
            <label htmlFor="question-type" id="question-type">
              {t('forum.createPost.questionTypeLabel')}
            </label>
            {/* Dropdown options */}
            <select
              name="questionType"
              id="question-type"
              autoFocus={true}
              value={formData.questionType}
              defaultValue={'solar'}
              onChange={handleChange}
            >
              <option value="solar">{t('forum.solar')}</option>
              <option value="wind">{t('forum.wind')}</option>
            </select>
          </div>
          <div className="form-control">
            <label htmlFor="question">
              {t('forum.createPost.postBodyLabel')}
            </label>
            <textarea
              name="body"
              id="question"
              value={formData.body}
              onChange={handleChange}
              className={dataErrors.body ? 'invalid' : null}
            ></textarea>
          </div>
          <div className="checkbox">
            <input
              name="agreePrivacyPolicy"
              type="checkbox"
              id="checkbox"
              value={formData.agreePrivacyPolicy}
              className={dataErrors.agreePrivacyPolicy ? 'invalid' : null}
              onChange={handleChange}
            />
            <label htmlFor="checkbox">
              <span>{t('forum.createPost.agreePrivacyPolicyLabel')}</span>
              <a className="checkbox--link" href={'/privacy-policy'}>
                {t('forum.createPost.agreePrivacyPolicyLink')}
              </a>
            </label>
          </div>

          <button
            type="submit"
            value="submit"
            disabled={Object.values(dataErrors).some((item) => item)}
          >
            {t('forum.createPost.publishButton')}
          </button>
        </form>
      </div>
    </div>
  )
}
