import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import MillIcon from '../../assets/millIcon.svg'
import ClientIcon from '../../assets/clientIcon.svg'
import RepairIcon from '../../assets/repairIcon.svg'
import ContentImg from '../../assets/mainPageImg.png'
import { Text } from '../../components/Text/Text'
import { LastNewsSlider } from '../../components/Sliders/LastNewsSlider/LastNewsSlider'
import { ServicesSlider } from '../../components/Sliders/ServicesSlider/ServicesSlider'
import './MainPage.scss'

export default function MainPage() {
  const { t } = useTranslation('global')
  let navigate = useNavigate()

  const scrollToBottom = () => {
    window.scroll({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div className="main-page--wrapper">
      <div className="main-page--banner">
        <div className="main-page--banner-desc">
          <h1 className="main-page--banner-desc--title">
            {t('mainPage.title')} <span>{t('mainPage.titleOrange')}</span>
          </h1>
          <p className="main-page--banner-desc--text">
            {t('mainPage.bannerDesc')}
          </p>
          <div className="main-page--banner-desc--buttons">
            <button
              className="main-page--banner-desc--buttons--btn"
              onClick={() => navigate('/catalog')}
            >
              {t('mainPage.bannerBtnCatalog')}
            </button>
            <button
              className="main-page--banner-desc--buttons--btn"
              onClick={scrollToBottom}
            >
              {t('mainPage.bannerBtnContact')}
            </button>
          </div>
        </div>
        <div className="main-page--banner-bottom">
          <div className="main-page--banner-bottom--item">
            <img src={MillIcon} alt={'mill'} />
            <div className="main-page--banner-bottom--item-desc">
              <span className="main-page--banner-bottom--item-desc-number">
                20+
              </span>
              <span className="main-page--banner-bottom--item-desc-text">
                {t('mainPage.bannerMills')}
              </span>
            </div>
          </div>
          <div className="main-page--banner-bottom--item main-page--banner-bottom--item_center">
            <img src={ClientIcon} alt={'client'} />
            <div className="main-page--banner-bottom--item-desc">
              <span className="main-page--banner-bottom--item-desc-number">
                20+
              </span>
              <span className="main-page--banner-bottom--item-desc-text">
                {t('mainPage.bannerClients')}
              </span>
            </div>
          </div>
          <div className="main-page--banner-bottom--item">
            <img src={RepairIcon} alt={'repair'} />
            <div className="main-page--banner-bottom--item-desc">
              <span className="main-page--banner-bottom--item-desc-number">
                30+
              </span>
              <span className="main-page--banner-bottom--item-desc-text">
                {t('mainPage.bannerRepairs')}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-page--content">
        <div className="main-page--content-info main-page--content-info_top">
          <div className="main-page--content-info--desc">
            <Text
              label={t('mainPage.contentMissionLabel')}
              title={t('mainPage.contentMissionTitle')}
              text={t('mainPage.contentMissionText')}
            />
          </div>
          <div className="main-page--content-info--desc-img">
            <img src={ContentImg} alt={'content'} />
          </div>
        </div>
        <ServicesSlider />
      </div>
      <div className="main-page--bottom-slider">
        <div className="main-page--bottom-slider-desc">
          <Text
            label={t('mainPage.contentNewsLabel')}
            title={t('mainPage.contentNewsTitle')}
            text={t('mainPage.contentNewsText')}
          />
        </div>
        <LastNewsSlider />
      </div>
    </div>
  )
}
