export default function Pagination({ page, onPageChanged, totalPages }) {
  return (
    <div className="forum-pagination">
      <button
        className="forum-pagination--item"
        onClick={() => onPageChanged(page - 1)}
        disabled={page === 1}
      >
        {'<'}
      </button>
      {page !== 1 ? (
        <p onClick={() => onPageChanged(1)} className="forum-pagination--item">
          {1}
        </p>
      ) : null}
      {page - 1 > 1 ? <p className="forum-pagination--item">...</p> : null}

      <p className="forum-pagination--item active">{page}</p>
      {totalPages - page > 1 ? (
        <p className="forum-pagination--item">...</p>
      ) : null}

      {page !== totalPages ? (
        <p
          onClick={() => onPageChanged(totalPages)}
          className="forum-pagination--item"
        >
          {totalPages}
        </p>
      ) : null}

      <button
        className="forum-pagination--item"
        onClick={() => onPageChanged(page + 1)}
        disabled={page === totalPages}
      >
        {'>'}
      </button>
    </div>
  )
}
