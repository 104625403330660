import { useCallback } from 'react'
import { collection, getCountFromServer } from 'firebase/firestore'

import { db } from '../config/firebase'

function useFetchPostsCount() {
  return useCallback(() => {
    const collectionRef = collection(db, 'posts')
    return getCountFromServer(collectionRef)
  }, [db])
}

export default useFetchPostsCount
