import { useCallback } from 'react'
import {
  collection,
  getCountFromServer,
  query,
  where,
} from 'firebase/firestore'

import { db } from '../config/firebase'

function useFetchCommentsCount() {
  return useCallback(
    (postId) => {
      const constraints = [where('postId', '==', postId)]

      const collectionRef = collection(db, 'comments')
      return getCountFromServer(query(collectionRef, ...constraints))
    },
    [db],
  )
}

export default useFetchCommentsCount
