import './ForgotPasswordPage.scss'
import ForumHeader from '../../components/ForumHeader/ForumHeader'

export default function ForgotPasswordPage() {
  return (
    <div className="content-wrapper">
      <ForumHeader />
      <div className="text-wrapper">
        <h1>Восстановление пароля</h1>
        <p>
          Для того, чтобы восстановить пароль от своего аккаунта, пожалуйста
          напшите письмо с просьбой восстановить пароль на адрес :{' '}
        </p>
        <a href="mailto:info@protonenergy.md">info@protonenergy.md</a>
      </div>
    </div>
  )
}
