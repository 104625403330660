import {
  collection,
  limit,
  query,
  startAfter,
  where,
  orderBy,
} from 'firebase/firestore'
import { useFirestoreCollection } from 'reactfire'
import { db } from '../config/firebase'

const converter = {
  toFirestore(post) {
    return {
      postId: post.postId,
      body: post.body,
      createdAt: post.createdAt,
      author: post.author,
    }
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      postId: data.postId,
      body: data.body,
      createdAt: data.createdAt,
      author: data.author,
    }
  },
}

function useFetchComments(postId, params) {
  const constraints = [
    where('postId', '==', postId),
    orderBy('createdAt', 'desc'),
    limit(params.itemsPerPage),
  ]

  if (params.cursor) {
    constraints.push(startAfter(params.cursor))
  }

  const collectionRef = collection(db, 'comments').withConverter(converter)

  const q = query(collectionRef, ...constraints)
  return useFirestoreCollection(q, { idField: 'id' })
}

export default useFetchComments
