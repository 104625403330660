import './NewsPage.scss'
import NewsCard from '../../components/NewsCard/NewsCard'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../config/firebase'
import { useEffect, useState } from 'react'
import Loader from '../../components/Loader/Loader'

export default function NewsPage() {
  const [news, setNews] = useState([])
  const [loading, setLoading] = useState(true)
  const newsCollectionRef = collection(db, 'news')

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const data = await getDocs(newsCollectionRef)
        const filteredDocs = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setNews(filteredDocs)
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
    fetchNews()
  }, [])

  return (
    <div>
      <div className="news-wrapper">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          news.map((newsItem) => (
            <NewsCard key={newsItem.id} newsItem={newsItem} scale />
          ))
        )}
      </div>
    </div>
  )
}
