import './CommentItem.scss'
import AvatarImage from '../../assets/avatar.svg'
import formatCreatedAt from '../../utils/formatCreatedAt'

export default function CommentItem({ comment }) {
  return (
    <div className="comment-container">
      <div className="comment-header">
        <div className="comment-header--left">
          <img
            className="comment-header--left--avatar"
            src={AvatarImage}
            alt="Avatar icon"
          />
          <div className="comment-header--left--name">
            <p className="comment-header--left--name--full-name">
              {comment.author.fullName}
            </p>
            <p className="comment-header--left--name--company-name">
              {comment.author.companyName}
            </p>
          </div>
        </div>
        <p className="comment-header--right">
          {formatCreatedAt(comment.createdAt)}
        </p>
      </div>
      <p className="comment-body">{comment.body}</p>
    </div>
  )
}
