import './SignInPage.scss'
import { auth, db } from '../../config/firebase'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { doc, getDoc } from 'firebase/firestore'
import { Link, useNavigate } from 'react-router-dom'
import ForumHeader from '../../components/ForumHeader/ForumHeader'
import { FirebaseAuthContext } from '../../context/auth'
import Loader from '../../components/Loader/Loader'

export default function SignInPage() {
  const { t } = useTranslation('global')
  const [error, setError] = useState(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { user, loading } = useContext(FirebaseAuthContext)
  let navigate = useNavigate()

  const signIn = async () => {
    try {
      const dbUser = await getDoc(doc(db, 'users', email))
      const userData = dbUser.data()
      if (userData) {
        if (!userData.disabled) {
          await signInWithEmailAndPassword(auth, email, password)
          navigate('/forum', { replace: true })
        } else {
          setError(t('auth.userDisabledError'))
          signOut(auth)
        }
      } else {
        setError(t('auth.firebaseError'))
      }
    } catch (err) {
      setError(t('auth.firebaseError'))
      console.log(err)
    }
  }

  useEffect(() => {
    if (user) {
      navigate('/forum', { replace: true })
    }
  }, [user, loading])

  return (
    <>
      <ForumHeader />
      <div className="content">
        <div className="content--content-title">
          <h1>{t('auth.logInTitle')}</h1>
          <p>{t('auth.logInText')}</p>
        </div>
        {loading ? (
          <Loader loading={true} />
        ) : (
          <div className="content--content-wrapper">
            <h2 className="content--content-wrapper--title">
              {t('auth.logInButton')}
            </h2>
            {error && (
              <h3 className="content--content-wrapper--error">{error}</h3>
            )}
            <form className="sign-in-form">
              <input
                placeholder={t('auth.emailPlaceholder')}
                value={email}
                name="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                placeholder={t('auth.passwordPlaceholder')}
                value={password}
                name="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Link to="/reset-password">
                <p>Забыли пароль?</p>
              </Link>
              <button
                type="button"
                onClick={async () => {
                  await signIn()
                }}
              >
                {t('auth.logInButton')}
              </button>
            </form>
          </div>
        )}
      </div>
    </>
  )
}
