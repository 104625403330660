import { MoonLoader } from 'react-spinners'

const override = {
  display: 'flex',
  margin: 'auto',
  borderColor: 'red',
}

export default function Loader({ loading }) {
  return (
    <MoonLoader
      color={'#F29E06'}
      cssOverride={override}
      loading={loading}
      size={150}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  )
}
