import {
  collection,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore'
import { useFirestoreCollection } from 'reactfire'
import { db } from '../config/firebase'

const converter = {
  toFirestore(post) {
    return {
      title: post.title,
      createdAt: post.createdAt,
      energyType: post.energyType,
      commentsNumber: post.commentsNumber,
      body: post.body,
    }
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      title: data.title,
      createdAt: data.createdAt,
      energyType: data.energyType,
      commentsNumber: data.commentsNumber,
      body: data.body,
    }
  },
}

function useFetchPosts(searchValue, params) {
  const constraints = [limit(params.itemsPerPage)]

  if (searchValue) {
    constraints.push(
      ...[
        where('title', '>=', searchValue),
        where('title', '<=', `${searchValue}~`),
      ],
    )
  } else {
    constraints.push(orderBy('createdAt', 'desc'))
  }

  if (params.cursor) {
    constraints.push(startAfter(params.cursor))
  }

  const collectionRef = collection(db, 'posts').withConverter(converter)
  const q = query(collectionRef, ...constraints)
  return useFirestoreCollection(q, { idField: 'id' })
}

export default useFetchPosts
