const formatCreatedAt = (createdAt) => {
  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }
  if (createdAt) {
    return new Date(createdAt.seconds * 1000).toLocaleString(
      'ru-RU',
      dateOptions,
    )
  } else {
    return new Date().toLocaleString('ru-RU', dateOptions)
  }
}

export default formatCreatedAt
