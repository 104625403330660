import { memo, useMemo } from 'react'
import './Select.scss'

const Select = memo((props) => {
  const { options, value, onChange } = props

  const optionsList = useMemo(
    () =>
      options?.map((opt) => (
        <option className="option" value={opt.value} key={opt.value}>
          {opt.content}
        </option>
      )),
    [options],
  )

  const onChangeHandler = (e) => {
    onChange?.(e.target.value)
  }

  return (
    <div className="wrapper">
      <select className="select" value={value} onChange={onChangeHandler}>
        {optionsList}
      </select>
    </div>
  )
})

Select.displayName = 'Select'

export default Select
