import { BrowserRouter } from 'react-router-dom'
import Router from './pages/Router/Router'
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop'

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <div className="App">
          <Router />
        </div>
      </ScrollToTop>
    </BrowserRouter>
  )
}

export default App
