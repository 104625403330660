import PoweredByImage from '../../assets/poweredByWhite.svg'
import SintechImage from '../../assets/sintech.svg'
import SearchImage from '../../assets/searchIcon.svg'
import AccountImage from '../../assets/accountIcon.svg'
import LogOut from '../../assets/logOut.svg'
import './ForumHeader.scss'
import { useContext, useState } from 'react'
import { FirebaseAuthContext } from '../../context/auth'
import { useTranslation } from 'react-i18next'

export default function ForumHeader({ handleSearch }) {
  const { user, logOut } = useContext(FirebaseAuthContext)
  const [search, setSearch] = useState('')
  const { t } = useTranslation('global')

  const formatUserName = (user) => {
    return `${user.firstName} ${user.lastName[0]}.`
  }

  const onSearch = (e) => {
    e.preventDefault()
    handleSearch(search)
  }

  const displayLogo = !handleSearch || !user

  return (
    <div className="forum">
      <div className="forum-header">
        <div className={displayLogo ? 'forum-logo display' : 'forum-logo'}>
          <div className="forum-word">{t('forum.header.forum')}</div>
          <div className="powered-by-images">
            <img src={PoweredByImage} alt="powered by" />
            <img src={SintechImage} alt="sintech" />
          </div>
        </div>
        {handleSearch && (
          <form onSubmit={onSearch}>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type="text"
              name="text"
              className="search"
              placeholder={t('forum.header.searchPlaceholder')}
            />
            <img src={SearchImage} alt="Search button" onClick={onSearch} />
          </form>
        )}

        {user && (
          <div className="account-wrapper">
            <img src={AccountImage} alt="" />
            <p className="account-name">{formatUserName(user)}</p>
            <img onClick={logOut} src={LogOut} alt="" className="log-out" />
          </div>
        )}
      </div>
    </div>
  )
}
