import { memo } from 'react'
import './Text.scss'

// eslint-disable-next-line react/display-name
export const Text = memo((props) => {
  const { title, label, text, className } = props

  return (
    <div className={`text ${className}`}>
      {label && (
        <div className="label">
          {label}
          <div className="underscore"></div>
        </div>
      )}
      {title && <h4 className="title">{title}</h4>}
      {text && <p className="text">{text}</p>}
    </div>
  )
})
