import './NewsCard.scss'
import { useTranslation } from 'react-i18next'
import DateIcon from '../../assets/dateIcon.svg'
import { Link } from 'react-router-dom'

export default function NewsCard({ newsItem, scale }) {
  const { t, i18n } = useTranslation('global')

  return (
    <Link to={`/news/${newsItem.id}`}>
      <div
        className={
          scale
            ? 'news-card-wrapper news-card-wrapper_scale'
            : 'news-card-wrapper'
        }
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.65) 40.45%, rgba(0, 0, 0, 0.00) 100%),' +
            `url(${newsItem.imageURL})`,
        }}
      >
        <div className="news-card--desc">
          <p className="news-card--desc-date">
            <img src={DateIcon} alt="date" />
            {new Date(newsItem.createdAt.seconds * 1000).toLocaleDateString()}
          </p>
          <h3 className="news-card--desc-title">
            {newsItem.title[i18n.resolvedLanguage]}
          </h3>
          <button className="news-card--desc-btn">
            {t('newsPage.readMoreButton')}
            <svg
              width="16"
              height="8"
              viewBox="0 0 16 8"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.3536 4.35356C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464468C11.9763 0.269206 11.6597 0.269206 11.4645 0.464468C11.2692 0.65973 11.2692 0.976313 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53554C11.6597 7.7308 11.9763 7.7308 12.1716 7.53554L15.3536 4.35356ZM-8.74228e-08 4.5L15 4.5L15 3.5L8.74228e-08 3.5L-8.74228e-08 4.5Z" />
            </svg>
          </button>
        </div>
      </div>
    </Link>
  )
}
