const {
  REACT_APP_FIREBASE_API_KEY = '',
  REACT_APP_FIREBASE_AUTH_DOMAIN = '',
  REACT_APP_FIREBASE_PROJECT_ID = '',
  REACT_APP_FIREBASE_STORAGE_BUCKET = '',
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID = '',
  REACT_APP_FIREBASE_APP_ID = '',
} = process.env

export default {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
}
