import { useTranslation } from 'react-i18next'
import './PrivacyPolicyPage.scss'

export default function PrivacyPolicyPage() {
  const { t } = useTranslation('global')

  return (
    <div className="privacy-policy">
      <h1 className="privacy-policy--title">{t('privacyPolicyTitle')}</h1>
      <p className="privacy-policy--body">{t('privacyPolicyBody')}</p>
    </div>
  )
}
