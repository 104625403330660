import { Fade } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import RepairImg from '../../../assets/repairImg.png'
import SupportImg from '../../../assets/supportImg.png'
import ConsultationImg from '../../../assets/consultationImg.png'
import { useTranslation } from 'react-i18next'
import './ServicesSlider.scss'
import { Text } from '../../Text/Text'
import { useNavigate } from 'react-router-dom'

export const ServicesSlider = () => {
  const { t } = useTranslation('global')
  let navigate = useNavigate()

  const indicators = (index) => <div className="indicator">{index + 1}</div>

  const Images = [
    {
      url: RepairImg,
      title: t('mainPage.contentServiceTitle'),
      text: t('mainPage.contentServiceText'),
    },
    {
      url: SupportImg,
      title: t('mainPage.contentSupportTitle'),
      text: t('mainPage.contentSupportText'),
    },
    {
      url: ConsultationImg,
      title: t('mainPage.contentConsultationTitle'),
      text: t('mainPage.contentConsultationText'),
    },
  ]

  return (
    <div className="slide-container">
      <Fade
        duration={6000}
        arrows={false}
        pauseOnHover={false}
        canSwipe={true}
        transitionDuration={500}
        indicators={indicators}
        autoplay={true}
      >
        {Images.map((fadeImage, index) => (
          <div key={index} className="main-page--content-info">
            <div className="main-page--content-info--desc-img">
              <img src={fadeImage.url} alt="slider" />
            </div>
            <div className="main-page--content-info--desc">
              <Text
                label={t('mainPage.contentServiceLabel')}
                title={fadeImage.title}
                text={fadeImage.text}
              />
              <button
                className="main-page--content-info--desc--btn"
                onClick={() => navigate('/catalog')}
              >
                {t('mainPage.contentServiceBtn')}
              </button>
            </div>
          </div>
        ))}
      </Fade>
    </div>
  )
}
